/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
	font-weight: 600;
	font-size: 12px;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-badge-small .mat-badge-content {
	font-size: 9px;
}

.mat-badge-large .mat-badge-content {
	font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
	font: 400 24px/32px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
	font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
	font: 400 16px/28px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
	font: 400 15px/24px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h5,
.mat-typography h5 {
	font: 400 calc(14px * 0.83) / 20px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 12px;
}

.mat-h6,
.mat-typography h6 {
	font: 400 calc(14px * 0.67) / 20px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2 {
	font: 500 14px/24px Roboto, 'Helvetica Neue', sans-serif;
}

.mat-body,
.mat-body-1,
.mat-typography {
	font: 400 14px/20px Roboto, 'Helvetica Neue', sans-serif;
}
.mat-body p,
.mat-body-1 p,
.mat-typography p {
	margin: 0 0 12px;
}

.mat-small,
.mat-caption {
	font: 400 12px/20px Roboto, 'Helvetica Neue', sans-serif;
}

.mat-display-4,
.mat-typography .mat-display-4 {
	font: 300 112px/112px Roboto, 'Helvetica Neue', sans-serif;
	letter-spacing: -0.05em;
	margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
	font: 400 56px/56px Roboto, 'Helvetica Neue', sans-serif;
	letter-spacing: -0.02em;
	margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
	font: 400 45px/48px Roboto, 'Helvetica Neue', sans-serif;
	letter-spacing: -0.005em;
	margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
	font: 400 34px/40px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0 0 64px;
}

.mat-bottom-sheet-container {
	font: 400 14px/20px Roboto, 'Helvetica Neue', sans-serif;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.mat-button-toggle {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-card {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-card-title {
	font-size: 24px;
	font-weight: 500;
}

.mat-card-header .mat-card-title {
	font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
	font-size: 14px;
}

.mat-checkbox {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
	line-height: 24px;
}

.mat-chip {
	font-size: 14px;
	font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
	font-size: 18px;
}

.mat-table {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-header-cell {
	font-size: 12px;
	font-weight: 500;
}

.mat-cell,
.mat-footer-cell {
	font-size: 14px;
}

.mat-calendar {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-calendar-body {
	font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
	font-size: 14px;
	font-weight: 500;
}

.mat-calendar-table-header th {
	font-size: 11px;
	font-weight: 400;
}

.mat-dialog-title {
	font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif;
}

.mat-expansion-panel-header {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 15px;
	font-weight: 400;
}

.mat-expansion-panel-content {
	font: 400 14px/20px Roboto, 'Helvetica Neue', sans-serif;
}

.mat-form-field {
	font-size: inherit;
	font-weight: 400;
	line-height: 1.125;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-form-field-wrapper {
	padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
	font-size: 150%;
	line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
	height: 1.5em;
	width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
	height: 1.125em;
	line-height: 1.125;
}

.mat-form-field-infix {
	padding: 0.5em 0;
	border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.34375em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.34374em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-label-wrapper {
	top: -0.84375em;
	padding-top: 0.84375em;
}

.mat-form-field-label {
	top: 1.34375em;
}

.mat-form-field-underline {
	bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
	font-size: 75%;
	margin-top: 0.66667em;
	top: calc(100% - 1.79167em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: 0.4375em 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
	-ms-transform: translateY(-1.28125em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
	.mat-form-field-autofill-control:-webkit-autofill
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
	-ms-transform: translateY(-1.28124em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
	.mat-input-server[label]:not(:label-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
	-ms-transform: translateY(-1.28123em) scale(0.75);
	width: 133.33335%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
	top: 1.28125em;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
	bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
	margin-top: 0.54167em;
	top: calc(100% - 1.66667em);
}

@media print {
	.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
	.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
		transform: translateY(-1.28122em) scale(0.75);
	}
	.mat-form-field-appearance-legacy.mat-form-field-can-float
		.mat-form-field-autofill-control:-webkit-autofill
		+ .mat-form-field-label-wrapper
		.mat-form-field-label {
		transform: translateY(-1.28121em) scale(0.75);
	}
	.mat-form-field-appearance-legacy.mat-form-field-can-float
		.mat-input-server[label]:not(:label-shown)
		+ .mat-form-field-label-wrapper
		.mat-form-field-label {
		transform: translateY(-1.2812em) scale(0.75);
	}
}

.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
	top: 1.09375em;
	margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-0.59375em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float
	.mat-input-server[label]:not(:label-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-0.59374em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 1em 0 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
	top: 1.84375em;
	margin-top: -0.25em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.59375em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float
	.mat-input-server[label]:not(:label-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.59374em) scale(0.75);
	width: 133.33334%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
	font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
	font-size: 12px;
}

input.mat-input-element {
	margin-top: -0.0625em;
}

.mat-menu-item {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 12px;
}

.mat-radio-button {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-select {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-select-trigger {
	height: 1.125em;
}

.mat-slide-toggle-content {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-slider-thumb-label-text {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 12px;
	font-weight: 500;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-step-label {
	font-size: 14px;
	font-weight: 400;
}

.mat-step-sub-label-error {
	font-weight: normal;
}

.mat-step-label-error {
	font-size: 14px;
}

.mat-step-label-selected {
	font-size: 14px;
	font-weight: 500;
}

.mat-tab-group {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-tab-label,
.mat-tab-link {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
	font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif;
	margin: 0;
}

.mat-tooltip {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 10px;
	padding-top: 6px;
	padding-bottom: 6px;
}

.mat-tooltip-handset {
	font-size: 14px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.mat-list-item {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-list-option {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-list-base .mat-list-item {
	font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n + 2) {
	font-size: 14px;
}

.mat-list-base .mat-list-option {
	font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n + 2) {
	font-size: 14px;
}

.mat-list-base .mat-subheader {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
	font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n + 2) {
	font-size: 12px;
}

.mat-list-base[dense] .mat-list-option {
	font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n + 2) {
	font-size: 12px;
}

.mat-list-base[dense] .mat-subheader {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 12px;
	font-weight: 500;
}

.mat-option {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 16px;
}

.mat-optgroup-label {
	font: 500 14px/24px Roboto, 'Helvetica Neue', sans-serif;
}

.mat-simple-snackbar {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
}

.mat-simple-snackbar-action {
	line-height: 1;
	font-family: inherit;
	font-size: inherit;
	font-weight: 500;
}

.mat-tree {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
	font-weight: 400;
	font-size: 14px;
}

.mat-ripple {
	overflow: hidden;
	position: relative;
}

.mat-ripple.mat-ripple-unbounded {
	overflow: visible;
}

.mat-ripple-element {
	position: absolute;
	border-radius: 50%;
	pointer-events: none;
	transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
	transform: scale(0);
}
@media (-ms-high-contrast: active) {
	.mat-ripple-element {
		display: none;
	}
}

.cdk-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.cdk-overlay-container {
	position: fixed;
	z-index: 1000;
}
.cdk-overlay-container:empty {
	display: none;
}

.cdk-global-overlay-wrapper {
	display: flex;
	position: absolute;
	z-index: 1000;
}

.cdk-overlay-pane {
	position: absolute;
	pointer-events: auto;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	max-width: 100%;
	max-height: 100%;
}

.cdk-overlay-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	pointer-events: auto;
	-webkit-tap-highlight-color: transparent;
	transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
	opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
	.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
		opacity: 0.6;
	}
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
	position: absolute;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	min-width: 1px;
	min-height: 1px;
}

.cdk-global-scrollblock {
	position: fixed;
	width: 100%;
	overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
	/*!*/
}

@keyframes cdk-text-field-autofill-end {
	/*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
	animation-name: cdk-text-field-autofill-start;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
	animation-name: cdk-text-field-autofill-end;
}

textarea.cdk-textarea-autosize {
	resize: none;
}

textarea.cdk-textarea-autosize-measuring {
	height: auto !important;
	overflow: hidden !important;
	padding: 2px 0 !important;
	box-sizing: content-box !important;
}

/* Color definitions */
/* Light theme */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/
/*it's necessary redefined the font size of the checkbox*/
/*
  Fixes https://github.com/OntimizeWeb/ontimize-web-ngx-theming/issues/53
   set line-height to normal as user-agent set in the inputs
   https://bugzilla.mozilla.org/show_bug.cgi?id=349259
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJbecmNE.woff2') format('woff2');
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecmNE.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecg.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

/*it's necessary redefined the font size of the checkbox*/
/*
  Fixes https://github.com/OntimizeWeb/ontimize-web-ngx-theming/issues/53
   set line-height to normal as user-agent set in the inputs
   https://bugzilla.mozilla.org/show_bug.cgi?id=349259
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.report-on-demand-sidenav .mat-expansion-panel {
	background-color: #eceff1;
}

[layout-padding-vertical] {
	padding: 4px 0;
}

[layout-margin-vertical] {
	margin: 4px 0;
}

[layout-padding-horizontal] {
	padding: 0 14px;
}

[layout-margin-horizontal] {
	margin: 0 14px;
}

[layout-padding-left] {
	padding-left: 14px;
}

[layout-margin-left] {
	margin-left: 14px;
}

[layout-padding-right] {
	padding-right: 14px;
}

[layout-margin-right] {
	margin-right: 14px;
}

[layout-padding-top] {
	padding-top: 4px;
}

[layout-margin-top] {
	margin-top: 4px;
}

[layout-padding-bottom] {
	padding-bottom: 4px;
}

[layout-margin-bottom] {
	margin-bottom: 4px;
}

/* CONTAINER */
.rounded-panel,
.standalone-chart-container,
.standalone-form-container .o-form-content-wrapper {
	background: white;
	border-radius: 2px;
}

.standalone-chart-container {
	margin-top: 48px;
}

.standalone-form-container .inner-form {
	padding: 16px;
}

/* APP SIDENAV */
.o-app-sidenav {
	font-weight: 400;
	color: #aaaaaa;
}

/* USER INFO */
o-app-header .o-app-header-title {
	font-weight: 700;
}

.o-app-header {
	background-color: white !important;
}

/* FORM */
.o-form-toolbar .mat-toolbar {
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.24);
	background-color: #eceff1;
	border-radius: 2px;
	min-height: initial;
}

/* TABS */
.mat-tab-group .mat-tab-label,
.mat-tab-group .mat-tab-link {
	opacity: 0.75;
}
.mat-tab-group .mat-tab-label.mat-tab-label-active,
.mat-tab-group .mat-tab-link.mat-tab-label-active {
	opacity: 0.95;
}

.mat-tab-group.o-tab-ontimize > .mat-tab-header {
	border: none;
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header .mat-tab-label-container .mat-tab-label {
	background: #d7dde0;
	padding: 0;
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header .mat-tab-label-container .mat-tab-label span.tab-label {
	text-overflow: clip;
	position: relative;
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header .mat-tab-label-container .mat-tab-label span.tab-label span.gradient-layer {
	width: 12px;
	background: linear-gradient(to right, transparent, #d7dde0 100%);
	height: 100%;
	position: absolute;
	right: 0;
}
.mat-tab-group.o-tab-ontimize
	> .mat-tab-header
	.mat-tab-label-container
	.mat-tab-label:not(.mat-tab-label-active):not(:last-child)
	+ .mat-tab-label:not(.mat-tab-label-active)
	.mat-tab-label-content {
	border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header .mat-tab-label-container .mat-tab-label .mat-tab-label-content {
	padding: 0 8px;
	width: 100%;
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header .mat-tab-label-container .mat-tab-label.mat-tab-label-active {
	background: white;
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header .mat-tab-label-container .mat-tab-label.mat-tab-label-active span.tab-label span.gradient-layer {
	background: linear-gradient(to right, transparent, white 100%);
}
.mat-tab-group.o-tab-ontimize > .mat-tab-header mat-ink-bar {
	display: none;
}

.mat-tab-group.o-tab-ontimize > .mat-tab-body-wrapper {
	background: white;
	border-radius: 2px;
	border-top: none;
	border-top-left-radius: initial;
}

.mat-tab-group.mat-tab-group .mat-tab-body-wrapper {
	padding: 8px;
}

/* BUTTON */
button.mat-button,
button.mat-flat-button,
button.mat-icon-button,
button.mat-raised-button,
button.mat-stroked-button {
	border-radius: 2px;
}

/* TABLE */
.ontimize-table.o-table {
	font-weight: 400;
}
.ontimize-table.o-table .o-table-container {
	border: none;
	border-radius: 2px;
	box-sizing: border-box;
	/*Toolbar*/
}
.ontimize-table.o-table .o-table-container .o-table-toolbar {
	margin: 0 0 4px;
	padding-top: 8px;
}
.ontimize-table.o-table .o-table-container .o-table-menu .o-table-option-active {
	background: rgba(0, 0, 0, 0.12);
}
.ontimize-table.o-table .o-table-container thead tr th {
	font-weight: 700;
}
.ontimize-table.o-table .o-table-container tbody tr.even,
.ontimize-table.o-table .o-table-container thead tr {
	background-color: inherit;
}
.ontimize-table.o-table .o-table-container tbody td.o-table-column-select-checkbox .mat-checkbox-inner-container .mat-checkbox-background,
.ontimize-table.o-table .o-table-container thead tr th.o-table-column-select-checkbox .mat-checkbox-inner-container .mat-checkbox-background {
	background-color: #1464a5;
}
.ontimize-table.o-table .o-table-container .mat-header-cell {
	font-weight: 700;
}
.ontimize-table.o-table .mat-paginator {
	background: inherit;
}
.ontimize-table.o-table .mat-table .mat-header-row .mat-header-cell {
	font-weight: 700;
}

.ontimize-table.o-table.o-table-fixed .o-scrollable-container {
	height: 100%;
	overflow-y: auto;
}

.mat-menu-panel.o-table-quickfilter-menu .mat-checkbox {
	padding: 2px 0;
}

.mat-menu-panel.o-search-input-menu .mat-checkbox {
	padding: 2px 0;
}

.o-table-quickfilter-menu .mat-checkbox {
	font-size: 14px;
}
.o-table-quickfilter-menu .mat-checkbox .mat-checkbox-inner-container {
	width: 16px;
	height: 16px;
}

.o-table.o-table .o-table-container .mat-table .mat-header-cell {
	font-weight: 600;
}
.o-table.o-table .o-table-container .mat-table .mat-header-cell .column-filter-icon {
	margin-right: 4px;
	width: 16px;
	height: 16px;
}

.o-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button .mat-button {
	height: 30px;
	border: none;
	padding: 0 6px;
	border-radius: 0;
}
.o-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button .mat-button.disabled {
	border: none;
	border-radius: 0;
}
.o-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button .mat-button:not(.disabled):hover {
	border: none;
	border-radius: 0;
}

.o-table.o-table .o-table-container .mat-table .mat-form-field .mat-form-field-infix {
	border-top: 0;
}

.o-table.o-table .o-table-container .mat-table .mat-row .mat-cell:not(.o-column-image):first-of-type {
	padding-left: 12px;
}

.o-table.o-table .o-table-container .mat-table .mat-row .mat-cell:last-of-type {
	padding-right: 12px;
}

.o-table.o-table .o-table-container .mat-table .mat-cell.mat-column-select,
.o-table.o-table .o-table-container .mat-table .mat-header-cell.mat-column-select {
	padding: 0 0 0 12px;
}

.o-table-export-dialog.o-table-export-dialog .mat-raised-button {
	width: 68px;
	height: 68px;
	min-width: 68px;
}
.o-table-export-dialog.o-table-export-dialog .mat-raised-button .mat-button-wrapper > div {
	line-height: 1;
}
.o-table-export-dialog.o-table-export-dialog .mat-raised-button .mat-icon {
	padding: 0 6px 6px;
	width: 38px;
	height: 38px;
	font-size: 38px;
}

.mat-form-field.mat-form-field {
	font-size: 14px;
}
.mat-form-field.mat-form-field.mat-form-field-invalid .mat-error {
	display: none;
}
.mat-form-field.mat-form-field.icon-field .mat-form-field-suffix .icon-btn,
.mat-form-field.mat-form-field.icon-field .mat-form-field-suffix .mat-icon-button,
.mat-form-field.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-wrapper .mat-form-field-prefix .mat-icon-button,
.mat-form-field.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-wrapper .mat-form-field-suffix .mat-icon-button {
	height: 1.4em;
	width: 1.4em;
}
.mat-form-field.mat-form-field.input-image.icon-field .mat-input-infix.mat-form-field-infix {
	line-height: 16px;
}
.mat-form-field.mat-form-field.icon-field .mat-form-field-suffix .mat-icon:not([svgicon]) {
	height: 20px;
	width: 20px;
}
.mat-form-field.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon {
	font-size: 140%;
}
.mat-form-field.mat-form-field .mat-form-field-wrapper {
	padding-bottom: 0em;
}
.mat-form-field.mat-form-field .mat-form-field-prefix .mat-icon,
.mat-form-field.mat-form-field .mat-form-field-suffix .mat-icon {
	font-size: 140%;
	height: 1em;
}
.mat-form-field.mat-form-field .mat-form-field-prefix .mat-icon-button,
.mat-form-field.mat-form-field .mat-form-field-suffix .mat-icon-button {
	height: 1.4em;
	width: 1.4em;
}
.mat-form-field.mat-form-field .mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field.mat-form-field .mat-form-field-suffix .mat-icon-button .mat-icon {
	height: 1em;
	line-height: 1;
}
.mat-form-field.mat-form-field .mat-form-field-infix {
	padding: 0.3em 0;
	border-top: 0.75em solid transparent;
}
.mat-form-field.mat-form-field .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field.mat-form-field .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.05em) scale(0.75);
	width: 133.33333%;
}
.mat-form-field.mat-form-field
	.mat-form-field-can-float
	.mat-input-server[label]:not(:placeholder-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.04999em) scale(0.75);
	width: 133.33334%;
}
.mat-form-field.mat-form-field .mat-form-field-label-wrapper {
	top: -0.75em;
	padding-top: 0.75em;
}
.mat-form-field.mat-form-field .mat-form-field-label {
	top: 1.05em;
}
.mat-form-field.mat-form-field .mat-form-field-underline {
	bottom: 0em;
}
.mat-form-field.mat-form-field .mat-form-field-subscript-wrapper {
	font-size: 75%;
	margin-top: 0.4em;
	top: calc(100% - 0em);
}

.o-combo mat-select {
	line-height: normal;
}

.o-checkbox .mat-checkbox {
	font-size: 14px;
}
.o-checkbox .mat-checkbox .mat-checkbox-ripple {
	left: calc(50% - 17px);
	top: calc(50% - 17px);
	height: 34px;
	width: 34px;
}

.o-checkbox .mat-checkbox-inner-container {
	width: 15px;
	height: 15px;
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 0.105em;
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: 0.35em 0;
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-label {
	top: 1.25em;
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-underline {
	bottom: 0.105em;
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
	margin-top: 0.4em;
	top: calc(100% - 0.14em);
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	padding: 0.25em 0.25em 0 0.25em !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0.25em 0 0.3em !important;
}

.mat-form-field-appearance-fill .mat-form-field-label {
	top: 1em !important;
	margin-top: -0.5em !important;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-0.5em) scale(0.75) !important;
	width: 133.33333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float
	.mat-input-server[label]:not(:placeholder-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-0.5em) scale(0.75) !important;
	width: 133.33333%;
}

.mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
	padding-bottom: 0;
}

.mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 0.3em 0;
}

.mat-form-field.mat-form-field-appearance-outline .mat-form-field-label {
	top: 1.05em;
	margin-top: -0.25em;
}

.mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float
	.mat-input-server:focus
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-0.8em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float
	.mat-input-server[label]:not(:placeholder-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-0.79999em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
	padding: 0.25em 0.25em 0 0.25em !important;
}

.o-column > .o-container > .o-container-title,
.o-row > .o-container > .o-container-title {
	line-height: 30px;
	height: 30px;
}

o-column.o-column,
o-row.o-row {
	background: transparent !important;
}

.o-column-collapsible > .o-container.mat-expansion-panel .mat-expansion-panel-header,
.o-row-collapsible > .o-container.mat-expansion-panel .mat-expansion-panel-header {
	padding-left: 4px;
}

.o-column-collapsible > .o-container.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body > .o-container-scroll,
.o-row-collapsible > .o-container.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body > .o-container-scroll {
	padding: 10px 6px 6px;
}

.o-column > .o-container .o-container-scroll.o-container-gap,
.o-row > .o-container .o-container-scroll.o-container-gap {
	padding: 10px 6px 6px;
}

.o-column.o-appearance-outline > .o-container .o-container-scroll.o-container-gap,
.o-row.o-appearance-outline > .o-container .o-container-scroll.o-container-gap {
	padding: 12px;
}

.o-column-collapsible.o-appearance-outline
	> .o-container.mat-expansion-panel
	.mat-expansion-panel-content
	.mat-expansion-panel-body
	> .o-container-scroll,
.o-row-collapsible.o-appearance-outline
	> .o-container.mat-expansion-panel
	.mat-expansion-panel-content
	.mat-expansion-panel-body
	> .o-container-scroll {
	padding: 12px;
}

/* O-APP-LAYOUT */
.o-app-sidenav.o-app-sidenav .o-app-header .sidenav-toggle {
	padding: 8px;
}

/* MD-TAB-GROUP */
.mat-tab-group.mat-tab-group > .mat-tab-header .mat-tab-label {
	height: 32px;
}

/* MAT-PAGINATOR */
.mat-paginator-container {
	min-height: 0;
}
.mat-paginator-container .mat-paginator-page-size {
	align-items: center;
}
.mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select {
	margin: 6px 0;
}
.mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select .mat-select-arrow-wrapper {
	transform: initial;
}
.mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select .mat-form-field-infix {
	border-top: 0;
}
.mat-paginator-container .mat-paginator-range-actions {
	min-height: 0;
}

.mat-tooltip-panel .o-tooltip.mat-tooltip.after,
.mat-tooltip-panel .o-tooltip.mat-tooltip.right {
	margin-left: 5px;
}

.mat-tooltip-panel .o-tooltip.mat-tooltip.before,
.mat-tooltip-panel .o-tooltip.mat-tooltip.left {
	margin-right: 5px;
}

.mat-tooltip-panel .o-tooltip.mat-tooltip.above {
	margin-bottom: -15px;
}

.mat-tooltip-panel .o-tooltip.mat-tooltip.below {
	margin-top: 5px;
}

.o-filter-by-column-dialog .select-all-checkbox {
	margin-top: 10px;
}

@media (max-width: 599px) {
	.o-list-picker-dialog.o-list-picker-dialog .mat-dialog-content.o-list-picker-has-filter {
		max-height: calc(100% - 105px);
		min-height: calc(100% - 105px);
	}
}

.o-search-input-menu .mat-checkbox {
	font-size: 14px;
}
.o-search-input-menu .mat-checkbox .mat-checkbox-inner-container {
	width: 16px;
	height: 16px;
}

[layout-padding] > [flex-lt-md],
[layout-padding] > [flex-sm] {
	padding: 2px;
}

[layout-padding],
[layout-padding] > [flex-gt-sm],
[layout-padding] > [flex-lt-lg],
[layout-padding] > [flex-md],
[layout-padding] > [flex] {
	padding: 4px;
}

[layout-padding] > [flex-gt-md],
[layout-padding] > [flex-lg] {
	padding: 8px;
}

[layout-margin] > [flex-lt-md],
[layout-margin] > [flex-sm] {
	margin: 2px;
}

[layout-margin],
[layout-margin] > [flex-gt-sm],
[layout-margin] > [flex-lt-lg],
[layout-margin] > [flex-md],
[layout-margin] > [flex] {
	margin: 4px;
}

[layout-margin] > [flex-gt-md],
[layout-margin] > [flex-lg] {
	margin: 8px;
}

[layout-wrap] {
	flex-wrap: wrap;
}

[layout-nowrap] {
	flex-wrap: nowrap;
}

[layout-padding-vertical] {
	padding: 4px 0;
}

[layout-margin-vertical] {
	margin: 4px 0;
}

[layout-padding-horizontal] {
	padding: 0 14px;
}

[layout-margin-horizontal] {
	margin: 0 14px;
}

[layout-padding-left] {
	padding-left: 14px;
}

[layout-margin-left] {
	margin-left: 14px;
}

[layout-padding-right] {
	padding-right: 14px;
}

[layout-margin-right] {
	margin-right: 14px;
}

[layout-padding-top] {
	padding-top: 4px;
}

[layout-margin-top] {
	margin-top: 4px;
}

[layout-padding-bottom] {
	padding-bottom: 4px;
}

[layout-margin-bottom] {
	margin-bottom: 4px;
}

.o-form .o-form-toolbar .o-form-toolbar-header {
	min-height: 36px;
	max-height: 36px;
	padding: 0 4px;
	box-shadow: none;
	font-size: 16px;
}
.o-form .o-form-toolbar .o-form-toolbar-header.breadcrumb {
	height: 28px;
	padding: 8px;
	min-height: 72px;
	max-height: 72px;
}
.o-form .o-form-toolbar .o-form-toolbar-header .mat-toolbar-tools .o-form-toolbar-button,
.o-form .o-form-toolbar .o-form-toolbar-header .mat-toolbar-tools .o-form-toolbar-button[disabled] {
	border-radius: 0;
}
.o-form .o-form-toolbar .o-form-toolbar-header .mat-toolbar-tools .o-form-toolbar-button.mat-icon-button,
.o-form .o-form-toolbar .o-form-toolbar-header .mat-toolbar-tools .o-form-toolbar-button[disabled].mat-icon-button {
	height: 30px;
	line-height: 30px;
	width: 30px;
}

/*
* After define theme, it is necessary to transfer color to Ontimize Web framework
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
	font-weight: 600;
	font-size: 12px;
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-badge-small .mat-badge-content {
	font-size: 9px;
}

.mat-badge-large .mat-badge-content {
	font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
	font: 400 24px/24px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
	font: 500 18px/24px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
	font: 600 14px/21px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
	font: 400 14px/18px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 16px;
}

.mat-h5,
.mat-typography h5 {
	font: 400 calc(12px * 0.83) / 15px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 12px;
}

.mat-h6,
.mat-typography h6 {
	font: 400 calc(12px * 0.67) / 15px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2 {
	font: 500 12px/18px Poppins, 'Helvetica Neue', sans-serif;
}

.mat-body,
.mat-body-1,
.mat-typography {
	font: 400 12px/15px Poppins, 'Helvetica Neue', sans-serif;
}
.mat-body p,
.mat-body-1 p,
.mat-typography p {
	margin: 0 0 12px;
}

.mat-small,
.mat-caption {
	font: 400 11px/15px Poppins, 'Helvetica Neue', sans-serif;
}

.mat-display-4,
.mat-typography .mat-display-4 {
	font: 300 84px/92px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
	font: 400 42px/42px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
	font: 400 34px/36px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
	font: 400 26px/30px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0 0 64px;
}

.mat-bottom-sheet-container {
	font: 400 12px/15px Poppins, 'Helvetica Neue', sans-serif;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 13px;
	font-weight: 500;
}

.mat-button-toggle {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-card {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-card-title {
	font-size: 24px;
	font-weight: 500;
}

.mat-card-header .mat-card-title {
	font-size: 18px;
}

.mat-card-subtitle,
.mat-card-content {
	font-size: 12px;
}

.mat-checkbox {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
	line-height: 18px;
}

.mat-chip {
	font-size: 12px;
	font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
	font-size: 18px;
}

.mat-table {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-header-cell {
	font-size: 11px;
	font-weight: 500;
}

.mat-cell,
.mat-footer-cell {
	font-size: 12px;
}

.mat-calendar {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-calendar-body {
	font-size: 11px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
	font-size: 13px;
	font-weight: 500;
}

.mat-calendar-table-header th {
	font-size: 9px;
	font-weight: 400;
}

.mat-dialog-title {
	font: 500 18px/24px Poppins, 'Helvetica Neue', sans-serif;
}

.mat-expansion-panel-header {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.mat-expansion-panel-content {
	font: 400 12px/15px Poppins, 'Helvetica Neue', sans-serif;
}

.mat-form-field {
	font: 400 14px/1 Poppins, 'Helvetica Neue', sans-serif;
}

.mat-form-field-wrapper {
	padding-bottom: 1.25em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
	font-size: 150%;
	line-height: 1;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
	height: 1.5em;
	width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
	height: 1em;
	line-height: 1;
}

.mat-form-field-infix {
	padding: 0.5em 0;
	border-top: 0.75em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.25em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.24999em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-label-wrapper {
	top: -0.75em;
	padding-top: 0.75em;
}

.mat-form-field-label {
	top: 1.25em;
}

.mat-form-field-underline {
	bottom: 1.25em;
}

.mat-form-field-subscript-wrapper {
	font-size: 75%;
	margin-top: 0.66667em;
	top: calc(100% - 1.66667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: 0.5em 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.25em) scale(0.75) perspective(100px) translateZ(0.001px);
	-ms-transform: translateY(-1.25em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
	.mat-form-field-autofill-control:-webkit-autofill
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.25em) scale(0.75) perspective(100px) translateZ(0.00101px);
	-ms-transform: translateY(-1.24999em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
	.mat-input-server[label]:not(:label-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.25em) scale(0.75) perspective(100px) translateZ(0.00102px);
	-ms-transform: translateY(-1.24998em) scale(0.75);
	width: 133.33335%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
	top: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
	bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
	margin-top: 0.66667em;
	top: calc(100% - 1.66667em);
}

@media print {
	.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
	.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
		transform: translateY(-1.24997em) scale(0.75);
	}
	.mat-form-field-appearance-legacy.mat-form-field-can-float
		.mat-form-field-autofill-control:-webkit-autofill
		+ .mat-form-field-label-wrapper
		.mat-form-field-label {
		transform: translateY(-1.24996em) scale(0.75);
	}
	.mat-form-field-appearance-legacy.mat-form-field-can-float
		.mat-input-server[label]:not(:label-shown)
		+ .mat-form-field-label-wrapper
		.mat-form-field-label {
		transform: translateY(-1.24995em) scale(0.75);
	}
}

.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
	top: 1em;
	margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-0.5em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float
	.mat-input-server[label]:not(:label-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-0.49999em) scale(0.75);
	width: 133.33334%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 1em 0 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
	top: 1.75em;
	margin-top: -0.25em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.5em) scale(0.75);
	width: 133.33333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float
	.mat-input-server[label]:not(:label-shown)
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateY(-1.49999em) scale(0.75);
	width: 133.33334%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
	font-size: 12px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
	font-size: 11px;
}

input.mat-input-element {
	margin-top: 0em;
}

.mat-menu-item {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 12px;
	font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 11px;
}

.mat-radio-button {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-select {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-select-trigger {
	height: 1em;
}

.mat-slide-toggle-content {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-slider-thumb-label-text {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 11px;
	font-weight: 500;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-step-label {
	font-size: 12px;
	font-weight: 400;
}

.mat-step-sub-label-error {
	font-weight: normal;
}

.mat-step-label-error {
	font-size: 12px;
}

.mat-step-label-selected {
	font-size: 12px;
	font-weight: 500;
}

.mat-tab-group {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-tab-label,
.mat-tab-link {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 13px;
	font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
	font: 500 18px/24px Poppins, 'Helvetica Neue', sans-serif;
	margin: 0;
}

.mat-tooltip {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 10px;
	padding-top: 6px;
	padding-bottom: 6px;
}

.mat-tooltip-handset {
	font-size: 14px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.mat-list-item {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-list-option {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-list-base .mat-list-item {
	font-size: 14px;
}
.mat-list-base .mat-list-item .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n + 2) {
	font-size: 12px;
}

.mat-list-base .mat-list-option {
	font-size: 14px;
}
.mat-list-base .mat-list-option .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n + 2) {
	font-size: 12px;
}

.mat-list-base .mat-subheader {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 12px;
	font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
	font-size: 11px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n + 2) {
	font-size: 11px;
}

.mat-list-base[dense] .mat-list-option {
	font-size: 11px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n + 2) {
	font-size: 11px;
}

.mat-list-base[dense] .mat-subheader {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 11px;
	font-weight: 500;
}

.mat-option {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 14px;
}

.mat-optgroup-label {
	font: 500 12px/18px Poppins, 'Helvetica Neue', sans-serif;
}

.mat-simple-snackbar {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 12px;
}

.mat-simple-snackbar-action {
	line-height: 1;
	font-family: inherit;
	font-size: inherit;
	font-weight: 500;
}

.mat-tree {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
	font-weight: 400;
	font-size: 12px;
}

.mat-ripple {
	overflow: hidden;
	position: relative;
}

.mat-ripple.mat-ripple-unbounded {
	overflow: visible;
}

.mat-ripple-element {
	position: absolute;
	border-radius: 50%;
	pointer-events: none;
	transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
	transform: scale(0);
}
@media (-ms-high-contrast: active) {
	.mat-ripple-element {
		display: none;
	}
}

.cdk-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.cdk-overlay-container {
	position: fixed;
	z-index: 1000;
}
.cdk-overlay-container:empty {
	display: none;
}

.cdk-global-overlay-wrapper {
	display: flex;
	position: absolute;
	z-index: 1000;
}

.cdk-overlay-pane {
	position: absolute;
	pointer-events: auto;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	max-width: 100%;
	max-height: 100%;
}

.cdk-overlay-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	pointer-events: auto;
	-webkit-tap-highlight-color: transparent;
	transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
	opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
	.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
		opacity: 0.6;
	}
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
	position: absolute;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	min-width: 1px;
	min-height: 1px;
}

.cdk-global-scrollblock {
	position: fixed;
	width: 100%;
	overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
	/*!*/
}

@keyframes cdk-text-field-autofill-end {
	/*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
	animation-name: cdk-text-field-autofill-start;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
	animation-name: cdk-text-field-autofill-end;
}

textarea.cdk-textarea-autosize {
	resize: none;
}

textarea.cdk-textarea-autosize-measuring {
	height: auto !important;
	overflow: hidden !important;
	padding: 2px 0 !important;
	box-sizing: content-box !important;
}

.mat-ripple-element {
	background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
	color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
	background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
	background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
	background: rgba(0, 0, 0, 0.04);
	color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
	color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #1464a5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #1464a5;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #f44336;
}

.mat-optgroup-label {
	color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
	color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
	color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
	color: #eceff1;
}

.mat-pseudo-checkbox-disabled {
	color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background: #1464a5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
	background: #1464a5;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
	background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
	background: #b0b0b0;
}

.mat-elevation-z0 {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
	box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
	box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
	box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
	box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
	box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
	box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
	box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
	box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
	box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
	box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
	box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
	box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
	background-color: #eceff1;
	color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
	display: none;
}

.mat-autocomplete-panel {
	background: white;
	color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
	background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
	color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
	color: white;
	background: #1464a5;
}
@media (-ms-high-contrast: active) {
	.mat-badge-content {
		outline: solid 1px;
		border-radius: 0;
	}
}

.mat-badge-accent .mat-badge-content {
	background: #1464a5;
	color: white;
}

.mat-badge-warn .mat-badge-content {
	color: white;
	background: #f44336;
}

.mat-badge {
	position: relative;
}

.mat-badge-hidden .mat-badge-content {
	display: none;
}

.mat-badge-disabled .mat-badge-content {
	background: #afb1b2;
	color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
	position: absolute;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	transition: transform 200ms ease-in-out;
	transform: scale(0.6);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
	transition: none;
}

.mat-badge-content.mat-badge-active {
	transform: none;
}

.mat-badge-small .mat-badge-content {
	width: 16px;
	height: 16px;
	line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
	top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
	bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
	left: -16px;
}

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
	left: auto;
	right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
	right: -16px;
}

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
	right: auto;
	left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -8px;
}

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -8px;
}

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -8px;
}

.mat-badge-medium .mat-badge-content {
	width: 22px;
	height: 22px;
	line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
	top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
	bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
	left: -22px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
	left: auto;
	right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
	right: -22px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
	right: auto;
	left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -11px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -11px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -11px;
}

.mat-badge-large .mat-badge-content {
	width: 28px;
	height: 28px;
	line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
	top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
	bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
	left: -28px;
}

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
	left: auto;
	right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
	right: -28px;
}

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
	right: auto;
	left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -14px;
}

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -14px;
}

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -14px;
}

.mat-bottom-sheet-container {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	background: white;
	color: rgba(0, 0, 0, 0.87);
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
	color: inherit;
	background: transparent;
}
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
	color: #1464a5;
}
.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
	color: #1464a5;
}
.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
	color: #f44336;
}
.mat-button.mat-primary[disabled],
.mat-button.mat-accent[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
	color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
	background-color: #1464a5;
}
.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
	background-color: #1464a5;
}
.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
	background-color: #f44336;
}
.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
	background-color: transparent;
}
.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
	opacity: 0.1;
	background-color: currentColor;
}

.mat-button-focus-overlay {
	background: black;
}

.mat-stroked-button:not([disabled]) {
	border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
	color: rgba(0, 0, 0, 0.87);
	background-color: white;
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
	color: white;
}
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
	color: white;
}
.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
	color: white;
}
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
	color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
	background-color: #1464a5;
}
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
	background-color: #1464a5;
}
.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
	background-color: #f44336;
}
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
	background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element {
	background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element {
	background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element {
	background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*='mat-elevation-z']),
.mat-flat-button:not([class*='mat-elevation-z']) {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*='mat-elevation-z']) {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*='mat-elevation-z']),
.mat-mini-fab:not([class*='mat-elevation-z']) {
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']),
.mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
	box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-fab[disabled]:not([class*='mat-elevation-z']),
.mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
	box-shadow: none;
}

.mat-button-toggle {
	color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
	background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
	color: rgba(0, 0, 0, 0.87);
	background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
	background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
	border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
	border-left: none;
	border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
	border-left: none;
	border-right: none;
	border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
	background-color: #e0e0e0;
	color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
	color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
	color: rgba(0, 0, 0, 0.26);
	background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
	background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
	background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
	border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
	background: white;
	color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*='mat-elevation-z']) {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
	color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
	border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
	fill: #eceff1;
}

.mat-checkbox-checkmark-path {
	stroke: #eceff1 !important;
}
@media (-ms-high-contrast: black-on-white) {
	.mat-checkbox-checkmark-path {
		stroke: #000 !important;
	}
}

.mat-checkbox-mixedmark {
	background-color: #eceff1;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
	background-color: #1464a5;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: #1464a5;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
	background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
	background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
	border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
	color: rgba(0, 0, 0, 0.54);
}

@media (-ms-high-contrast: active) {
	.mat-checkbox-disabled {
		opacity: 0.5;
	}
}

@media (-ms-high-contrast: active) {
	.mat-checkbox-background {
		background: none;
	}
}

.mat-checkbox .mat-ripple-element {
	background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
	background: #1464a5;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
	background: #1464a5;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
	background: #f44336;
}

.mat-chip.mat-standard-chip {
	background-color: #e0e0e0;
	color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
	color: rgba(0, 0, 0, 0.87);
	opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
	box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
	opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
	opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
	background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
	background-color: #1464a5;
	color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
	color: white;
	opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
	background: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
	background-color: #f44336;
	color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
	color: white;
	opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
	background: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
	background-color: #1464a5;
	color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
	color: white;
	opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
	background: rgba(255, 255, 255, 0.1);
}

.mat-table {
	background: white;
}

.mat-table thead,
.mat-table tbody,
.mat-table tfoot,
mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-table-sticky {
	background: inherit;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
	border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
	color: rgba(0, 0, 0, 0.54);
}

.mat-cell,
.mat-footer-cell {
	color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
	border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
	color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
	color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
	background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
	color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
	color: rgba(0, 0, 0, 0.87);
	border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
	color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
	background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
	border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
	border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
	background-color: #1464a5;
	color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
	background-color: rgba(20, 100, 165, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	background-color: white;
	color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
	background-color: #1464a5;
	color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
	background-color: rgba(20, 100, 165, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
	background-color: #f44336;
	color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
	background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
	color: #1464a5;
}
.mat-datepicker-toggle-active.mat-accent {
	color: #1464a5;
}
.mat-datepicker-toggle-active.mat-warn {
	color: #f44336;
}

.mat-mdc-dialog-container {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	background: white;
	color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
	border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
	border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
	background: white;
	color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*='mat-elevation-z']) {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
	border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
	background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
	.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
		background: white;
	}
}

.mat-expansion-panel-header-title {
	color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
	color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled='true'] {
	color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
	color: inherit;
}

.mat-form-field-label {
	color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
	color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: #1464a5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
	color: #1464a5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
	color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
	color: #1464a5;
}

.mat-form-field-ripple {
	background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: #1464a5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
	background-color: #1464a5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
	background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
	color: #1464a5;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
	color: #1464a5;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
	color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
	color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
	color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
	background-color: #f44336;
}

.mat-error {
	color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
	color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-hint {
	color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
	background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
	background-size: 4px 100%;
	background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
	background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
	background-size: 4px 100%;
	background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: rgba(0, 0, 0, 0.04);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
	background-color: rgba(0, 0, 0, 0.02);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
	background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
	color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
	background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	color: rgba(0, 0, 0, 0.12);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: rgba(0, 0, 0, 0.87);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: #1464a5;
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
	color: #1464a5;
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
	color: #f44336;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
	color: #f44336;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
	color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
	color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
	color: #1464a5;
}

.mat-icon.mat-accent {
	color: #1464a5;
}

.mat-icon.mat-warn {
	color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
	color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
	color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
	caret-color: #1464a5;
}
.mat-input-element::placeholder {
	color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
	color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
	caret-color: #1464a5;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
	caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
	color: #f44336;
}

.mat-list-base .mat-list-item {
	color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-list-option {
	color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-subheader {
	color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
	background-color: #eeeeee;
}

.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
	background: rgba(0, 0, 0, 0.04);
}

.mat-menu-panel {
	background: white;
}
.mat-menu-panel:not([class*='mat-elevation-z']) {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
	background: transparent;
	color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled]::after {
	color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
	color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
	background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
	background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
	color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
	border-top: 2px solid rgba(0, 0, 0, 0.54);
	border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
	border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
	border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
	fill: #f3f7fa;
}

.mat-progress-bar-buffer {
	background-color: #f3f7fa;
}

.mat-progress-bar-fill::after {
	background-color: #1464a5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
	fill: #b9d1e4;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
	background-color: #b9d1e4;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
	background-color: #1464a5;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
	fill: #ffcdd2;
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
	background-color: #ffcdd2;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
	background-color: #f44336;
}

.mat-progress-spinner circle,
.mat-spinner circle {
	stroke: #1464a5;
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
	stroke: #1464a5;
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
	stroke: #f44336;
}

.mat-radio-outer-circle {
	border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
	border-color: #1464a5;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
	background-color: #1464a5;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #1464a5;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #1464a5;
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
	border-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
	background-color: #f44336;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
	border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
	background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
	color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button .mat-ripple-element {
	background-color: black;
}

.mat-select-value {
	color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
	color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
	color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
	color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
	background: white;
}
.mat-select-panel:not([class*='mat-elevation-z']) {
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
	background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
	color: #1464a5;
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
	color: #1464a5;
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
	color: #f44336;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
	color: #f44336;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
	color: rgba(0, 0, 0, 0.38);
}
.o-app-sidenav .o-app-sidenav-container .mat-drawer.o-app-sidenav-sidenav {
	width: 265px !important;
}

.mat-drawer-container {
	background-color: #eceff1;
	color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
	background-color: white;
	color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
	background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
	border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
	border-left: solid 1px rgba(0, 0, 0, 0.12);
	border-right: none;
}

[dir='rtl'] .mat-drawer-side {
	border-left: solid 1px rgba(0, 0, 0, 0.12);
	border-right: none;
}
[dir='rtl'] .mat-drawer-side.mat-drawer-end {
	border-left: none;
	border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
	background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: #1464a5;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(20, 100, 165, 0.54);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
	background-color: #1464a5;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
	background-color: #1464a5;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(20, 100, 165, 0.54);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
	background-color: #1464a5;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
	background-color: #f44336;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(244, 67, 54, 0.54);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
	background-color: #f44336;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
	background-color: black;
}

.mat-slide-toggle-thumb {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	background-color: #fafafa;
}

.mat-slide-toggle-bar {
	background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
	background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
	background-color: #1464a5;
}

.mat-primary .mat-slider-thumb-label-text {
	color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
	background-color: #1464a5;
}

.mat-accent .mat-slider-thumb-label-text {
	color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
	background-color: #f44336;
}

.mat-warn .mat-slider-thumb-label-text {
	color: white;
}

.mat-slider-focus-ring {
	background-color: rgba(20, 100, 165, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
	background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
	background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-disabled:hover .mat-slider-track-background {
	background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
	background-color: rgba(0, 0, 0, 0.12);
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
	background-color: rgba(0, 0, 0, 0.87);
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
	background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
	border-color: rgba(0, 0, 0, 0.26);
	background-color: transparent;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
	border-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
	border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
	border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
	background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
	background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
	background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
	.mat-step-header:hover {
		background: none;
	}
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
	color: rgba(0, 0, 0, 0.54);
}

.mat-step-header .mat-step-icon {
	background-color: rgba(0, 0, 0, 0.54);
	color: white;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: #1464a5;
	color: white;
}

.mat-step-header .mat-step-icon-state-error {
	background-color: transparent;
	color: #f44336;
}

.mat-step-header .mat-step-label.mat-step-label-active {
	color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-label.mat-step-label-error {
	color: #f44336;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
	background-color: white;
}

.mat-stepper-vertical-line::before {
	border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
	border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
	color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	border-bottom: none;
}

.mat-tab-label,
.mat-tab-link {
	color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
	color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
	border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
	border-bottom: none;
	border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(243, 247, 250, 0.3);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: #1464a5;
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
	background-color: white;
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(185, 209, 228, 0.3);
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
	background-color: #1464a5;
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
	background-color: white;
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
	background-color: #f44336;
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
	background-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(243, 247, 250, 0.3);
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-group.mat-background-primary .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
	background-color: #1464a5;
}

.mat-tab-group.mat-background-primary .mat-tab-label,
.mat-tab-group.mat-background-primary .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
	color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
	color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
	border-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
	background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(185, 209, 228, 0.3);
}

.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-group.mat-background-accent .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
	background-color: #1464a5;
}

.mat-tab-group.mat-background-accent .mat-tab-label,
.mat-tab-group.mat-background-accent .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link {
	color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
	color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
	border-color: white;
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
	background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-background-warn .mat-tab-header,
.mat-tab-group.mat-background-warn .mat-tab-links,
.mat-tab-group.mat-background-warn .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn .mat-tab-links,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
	background-color: #f44336;
}

.mat-tab-group.mat-background-warn .mat-tab-label,
.mat-tab-group.mat-background-warn .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link {
	color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
	color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
	border-color: white;
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
	background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
	background: whitesmoke;
	color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
	background: #1464a5;
	color: white;
}
.mat-toolbar.mat-accent {
	background: #1464a5;
	color: white;
}
.mat-toolbar.mat-warn {
	background: #f44336;
	color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
	background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
	color: inherit;
}
.mat-toolbar .mat-input-element {
	caret-color: currentColor;
}

.mat-tooltip {
	background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
	background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
	color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
	color: rgba(255, 255, 255, 0.7);
	background: #323232;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
	color: #1464a5;
}

body,
html {
	background-color: #eceff1;
}

button,
html,
input,
select,
span,
textarea {
	font-family: Poppins, 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: transparent;
	opacity: 1;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	border-radius: 0;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 0;
	-webkit-box-shadow: none;
	background-color: #8ab2d2;
	opacity: 1;
}

.o-loading {
	display: inline-block;
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	z-index: 999;
}
.o-loading div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	margin: 6px;
	border: 3px solid;
	border-radius: 50%;
	left: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #1464a5 transparent transparent transparent;
}
.o-loading div:nth-child(1) {
	animation-delay: -0.45s;
}
.o-loading div:nth-child(2) {
	animation-delay: -0.3s;
}
.o-loading div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* MODAL DIALOG IN O-DIALOG, FORM LAYOUT MANAGER AND LIST-PICKER */
.o-dialog-class .mat-mdc-dialog-container .mat-dialog-title,
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-title,
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-title {
	border-bottom: 1px solid #1464a5;
	line-height: 14px;
	font-size: 14px;
}
.o-dialog-class .mat-mdc-dialog-container .mat-dialog-title .mat-icon,
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-title .mat-icon,
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-title .mat-icon {
	color: #1464a5;
}

.o-dialog-class .mat-mdc-dialog-container .empty-filter-list,
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .empty-filter-list,
.o-list-picker-dialog .mat-mdc-dialog-container .empty-filter-list {
	color: rgba(0, 0, 0, 0.38);
}

.o-dialog-class .mat-mdc-dialog-container .mat-dialog-content,
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-content,
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-content {
	font-size: 12px;
}
.o-dialog-class .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense],
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense],
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] {
	outline: none;
}
.o-dialog-class .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .empty-filter-list,
.o-dialog-class .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .mat-list-item-content > *,
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .empty-filter-list,
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .mat-list-item-content > *,
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .empty-filter-list,
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .mat-list-item-content > * {
	font-size: 12px;
}
.o-dialog-class .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.o-form-layout-dialog-overlay .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n + 2),
.o-list-picker-dialog .mat-mdc-dialog-container .mat-dialog-content .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n + 2) {
	font-size: 11px;
}

/*global styles about drag/drop in dialogs table*/
.cdk-drag-preview {
	width: 100%;
	display: block;
}
.cdk-drag-preview .mat-list-item-content {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.87);
	padding: 6px;
	flex-direction: row;
	align-items: center;
	background-color: #eceff1;
	display: flex;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	border-radius: 2px;
}
.cdk-drag-preview .mat-list-item-content .mat-list-icon {
	padding: 4px;
}
.cdk-drag-preview .mat-list-item-content .mat-list-text {
	padding-left: 16px;
	width: 100%;
}

.cdk-drop-list-dragging .o-drag-list-item-box:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-checkbox .mat-checkbox-label {
	color: rgba(0, 0, 0, 0.87);
}

o-data-toolbar.o-grid-toolbar button.o-grid-button,
o-data-toolbar.o-list-toolbar button.o-list-button,
o-data-toolbar.o-table-toolbar .buttons .o-table-button,
o-data-toolbar.o-table-toolbar .buttons button {
	margin: 0 4px;
}
o-data-toolbar.o-grid-toolbar button.o-grid-button:first-child,
o-data-toolbar.o-list-toolbar button.o-list-button:first-child,
o-data-toolbar.o-table-toolbar .buttons .o-table-button:first-child,
o-data-toolbar.o-table-toolbar .buttons button:first-child {
	margin-left: 0;
}
o-data-toolbar.o-grid-toolbar button.o-grid-button:last-child,
o-data-toolbar.o-list-toolbar button.o-list-button:last-child,
o-data-toolbar.o-table-toolbar .buttons .o-table-button:last-child,
o-data-toolbar.o-table-toolbar .buttons button:last-child {
	margin-right: 0;
}

.mat-dialog-actions button,
.o-form .o-form-toolbar button {
	margin: 0 8px;
}
.mat-dialog-actions button:first-child,
.o-form .o-form-toolbar button:first-child {
	margin-left: 0;
}
.mat-dialog-actions button:last-child,
.o-form .o-form-toolbar button:last-child {
	margin-right: 0;
}

/*BUTTONS HOVER*/
.mat-dialog-actions button:not([disabled]).mat-button-base.mat-primary,
.mat-dialog-actions button:not([disabled]).mat-button-base.o-button-primary,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'],
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'],
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.mat-primary,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.o-button-primary,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'],
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'],
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.mat-primary,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.o-button-primary,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'],
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'],
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.mat-primary,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.o-button-primary,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'],
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'],
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.mat-primary,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.o-button-primary,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'],
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'] {
	color: #0b488a;
	background-color: #f3f7fa;
	border-color: transparent;
}
.mat-dialog-actions button:not([disabled]).mat-button-base.mat-primary:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base.mat-primary .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base.o-button-primary:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base.o-button-primary .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0']:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'] .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true']:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'] .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.mat-primary:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.mat-primary .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.o-button-primary:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.o-button-primary .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0']:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'] .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true']:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'] .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.mat-primary:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.mat-primary .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.o-button-primary:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.o-button-primary .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0']:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'] .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true']:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'] .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.mat-primary:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.mat-primary .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.o-button-primary:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.o-button-primary .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0']:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0'] .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true']:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true'] .mat-button-focus-overlay,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.mat-primary:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base.mat-primary
	.mat-button-focus-overlay,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.o-button-primary:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base.o-button-primary
	.mat-button-focus-overlay,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0']:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='0']
	.mat-button-focus-overlay,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true']:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='true']
	.mat-button-focus-overlay {
	color: #1464a5;
	background-color: #ffffff;
	border-color: rgba(0, 0, 0, 0.12);
}

.mat-dialog-actions button:not([disabled]).mat-button-base,
.mat-dialog-actions button:not([disabled]).mat-button-base.mat-accent,
.mat-dialog-actions button:not([disabled]).mat-button-base.o-button-default,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'],
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'],
.o-form .o-form-toolbar button:not([disabled]).mat-button-base,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.mat-accent,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.o-button-default,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'],
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'],
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.mat-accent,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.o-button-default,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'],
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'],
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.mat-accent,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.o-button-default,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'],
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'],
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.mat-accent,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.o-button-default,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'],
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'] {
	color: #1464a5;
	background-color: #ffffff;
}
.mat-dialog-actions button:not([disabled]).mat-button-base:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base.mat-accent:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base.mat-accent .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base.o-button-default:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base.o-button-default .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1']:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'] .mat-button-focus-overlay,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false']:hover,
.mat-dialog-actions button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'] .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.mat-accent:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.mat-accent .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.o-button-default:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base.o-button-default .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1']:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'] .mat-button-focus-overlay,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false']:hover,
.o-form .o-form-toolbar button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'] .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.mat-accent:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.mat-accent .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.o-button-default:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base.o-button-default .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1']:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'] .mat-button-focus-overlay,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false']:hover,
.o-grid .o-grid-toolbar button.o-grid-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'] .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.mat-accent:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.mat-accent .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.o-button-default:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base.o-button-default .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1']:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1'] .mat-button-focus-overlay,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false']:hover,
.o-list .o-list-toolbar button.o-list-button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false'] .mat-button-focus-overlay,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base:hover,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base .mat-button-focus-overlay,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.mat-accent:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base.mat-accent
	.mat-button-focus-overlay,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button:not([disabled]).mat-button-base.o-button-default:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base.o-button-default
	.mat-button-focus-overlay,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1']:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='1']
	.mat-button-focus-overlay,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false']:hover,
.ontimize-table.o-table
	.o-table-container
	.o-table-toolbar
	.buttons
	.o-table-button
	button:not([disabled]).mat-button-base[ng-reflect-dialog-result='false']
	.mat-button-focus-overlay {
	color: #0b488a;
	background-color: #f3f7fa;
	border-color: transparent;
}

.o-form .o-form-toolbar [o-form-toolbar-buttons] > * {
	margin: 0 8px;
}
.o-form .o-form-toolbar [o-form-toolbar-buttons] > *:first-child {
	margin-left: 0;
}

.o-button button.mat-stroked-button:not([disabled]).mat-primary {
	color: #0b488a;
	background-color: #f3f7fa;
	border-color: transparent;
}
.o-button button.mat-stroked-button:not([disabled]).mat-primary:hover,
.o-button button.mat-stroked-button:not([disabled]).mat-primary .mat-button-focus-overlay {
	color: #1464a5;
	background-color: #ffffff;
	border-color: rgba(0, 0, 0, 0.12);
}

.o-button button.mat-stroked-button:not([disabled]).mat-accent {
	color: #1464a5;
	background-color: #ffffff;
}
.o-button button.mat-stroked-button:not([disabled]).mat-accent:hover,
.o-button button.mat-stroked-button:not([disabled]).mat-accent .mat-button-focus-overlay {
	color: #0b488a;
	background-color: #f3f7fa;
	border-color: transparent;
}

.o-form .o-form-toolbar button.mat-stroked-button,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button.mat-stroked-button {
	padding: 0 6px;
}
.o-form .o-form-toolbar button.mat-stroked-button .mat-button-wrapper .mat-icon,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button.mat-stroked-button .mat-button-wrapper .mat-icon {
	margin-right: 4px;
	font-size: 20px;
	width: 20px;
	height: 20px;
	line-height: 20px;
}
.o-form .o-form-toolbar button.mat-stroked-button .mat-button-wrapper span,
.ontimize-table.o-table .o-table-container .o-table-toolbar .buttons .o-table-button button.mat-stroked-button .mat-button-wrapper span {
	flex: 1;
}

/*MAT PAGINATION*/
.mat-paginator .mat-paginator-range-label {
	margin: 0 16px;
}

.mat-paginator .mat-paginator-page-size-label {
	margin-right: 16px;
}

.mat-paginator button:hover {
	background-color: #f3f7fa;
	border-radius: 50%;
}

.mat-paginator button.mat-icon-button {
	height: 20px;
	width: 20px;
	line-height: 20px;
	margin-left: 8px;
}
.mat-paginator button.mat-icon-button .mat-paginator-icon {
	width: 20px;
}

/*MAT MENU*/
.o-mat-menu.mat-menu-panel {
	min-height: auto;
}

/* CHART FONT */
o-chart .nvd3 text {
	font: 400 11px Poppins, 'Helvetica Neue', sans-serif;
	fill: rgba(0, 0, 0, 0.87);
}

o-chart .nvd3 .nv-discretebar .nv-groups text,
o-chart .nvd3 .nv-multibarHorizontal .nv-groups text {
	fill: rgba(0, 0, 0, 0.87);
}

o-chart .nvd3 .nv-axis path {
	stroke: rgba(0, 0, 0, 0.87);
}

.toolbar-breadcrumb {
	background-color: whitesmoke;
}
.toolbar-breadcrumb a {
	color: #1464a5;
}
.toolbar-breadcrumb mat-icon {
	color: #1464a5;
}

.o-column > .o-container.mat-expansion-panel,
.o-column-collapsible > .o-container.mat-expansion-panel,
.o-row > .o-container.mat-expansion-panel,
.o-row-collapsible > .o-container.mat-expansion-panel {
	background-color: inherit;
}

.o-column > .o-container > .o-container-header,
.o-column > .o-container > .o-container-title,
.o-column > .o-container > .mat-expansion-panel-header,
.o-column-collapsible > .o-container > .o-container-header,
.o-column-collapsible > .o-container > .o-container-title,
.o-column-collapsible > .o-container > .mat-expansion-panel-header,
.o-row > .o-container > .o-container-header,
.o-row > .o-container > .o-container-title,
.o-row > .o-container > .mat-expansion-panel-header,
.o-row-collapsible > .o-container > .o-container-header,
.o-row-collapsible > .o-container > .o-container-title,
.o-row-collapsible > .o-container > .mat-expansion-panel-header {
	border-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.87);
}
.o-column > .o-container > .o-container-header .mat-tab-label,
.o-column > .o-container > .o-container-header .mat-tab-link,
.o-column > .o-container > .o-container-title .mat-tab-label,
.o-column > .o-container > .o-container-title .mat-tab-link,
.o-column > .o-container > .mat-expansion-panel-header .mat-tab-label,
.o-column > .o-container > .mat-expansion-panel-header .mat-tab-link,
.o-column-collapsible > .o-container > .o-container-header .mat-tab-label,
.o-column-collapsible > .o-container > .o-container-header .mat-tab-link,
.o-column-collapsible > .o-container > .o-container-title .mat-tab-label,
.o-column-collapsible > .o-container > .o-container-title .mat-tab-link,
.o-column-collapsible > .o-container > .mat-expansion-panel-header .mat-tab-label,
.o-column-collapsible > .o-container > .mat-expansion-panel-header .mat-tab-link,
.o-row > .o-container > .o-container-header .mat-tab-label,
.o-row > .o-container > .o-container-header .mat-tab-link,
.o-row > .o-container > .o-container-title .mat-tab-label,
.o-row > .o-container > .o-container-title .mat-tab-link,
.o-row > .o-container > .mat-expansion-panel-header .mat-tab-label,
.o-row > .o-container > .mat-expansion-panel-header .mat-tab-link,
.o-row-collapsible > .o-container > .o-container-header .mat-tab-label,
.o-row-collapsible > .o-container > .o-container-header .mat-tab-link,
.o-row-collapsible > .o-container > .o-container-title .mat-tab-label,
.o-row-collapsible > .o-container > .o-container-title .mat-tab-link,
.o-row-collapsible > .o-container > .mat-expansion-panel-header .mat-tab-label,
.o-row-collapsible > .o-container > .mat-expansion-panel-header .mat-tab-link {
	color: rgba(0, 0, 0, 0.87);
}

.o-column > .o-container > .o-container-title span,
.o-column > .o-container > .mat-expansion-panel-header .o-container-title span,
.o-column-collapsible > .o-container > .o-container-title span,
.o-column-collapsible > .o-container > .mat-expansion-panel-header .o-container-title span,
.o-row > .o-container > .o-container-title span,
.o-row > .o-container > .mat-expansion-panel-header .o-container-title span,
.o-row-collapsible > .o-container > .o-container-title span,
.o-row-collapsible > .o-container > .mat-expansion-panel-header .o-container-title span {
	font-size: 14px;
	font-weight: 400;
}

.o-column-collapsible > .o-container .o-container-title.mat-expansion-panel-header-title,
.o-row-collapsible > .o-container .o-container-title.mat-expansion-panel-header-title {
	border: none;
}

.o-column-collapsible.o-appearance-outline > .o-container .o-container-title,
.o-column.o-appearance-outline > .o-container .o-container-title,
.o-row-collapsible.o-appearance-outline > .o-container .o-container-title,
.o-row.o-appearance-outline > .o-container .o-container-title {
	color: rgba(0, 0, 0, 0.87);
}

.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-end,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-description,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-empty1,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-empty2,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-icon,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-title,
.o-column-collapsible.o-appearance-outline .o-container-outline .o-container-outline-start,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-end,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-gap,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-gap-description,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-gap-empty1,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-gap-empty2,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-gap-icon,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-gap-title,
.o-column.o-appearance-outline .o-container-outline .o-container-outline-start,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-end,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-description,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-empty1,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-empty2,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-icon,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-gap-title,
.o-row-collapsible.o-appearance-outline .o-container-outline .o-container-outline-start,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-end,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-gap,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-gap-description,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-gap-empty1,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-gap-empty2,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-gap-icon,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-gap-title,
.o-row.o-appearance-outline .o-container-outline .o-container-outline-start {
	border-color: rgba(0, 0, 0, 0.12);
}

.o-form-toolbar-header {
	background-color: whitesmoke;
}

/* outline appearance */
.mat-form-field {
	/* OUTLINE APPEARANCE */
}
.mat-form-field.mat-form-field-disabled.accent .icon-btn,
.mat-form-field.mat-form-field-disabled.accent .mat-icon,
.mat-form-field.mat-form-field-disabled.accent .read-only .mat-icon,
.mat-form-field.mat-form-field-disabled.accent .svg-icon {
	color: rgba(20, 100, 165, 0.42);
}
.mat-form-field:not(.mat-form-field-disabled) .icon-btn,
.mat-form-field:not(.mat-form-field-disabled) .mat-icon,
.mat-form-field:not(.mat-form-field-disabled) .read-only .mat-icon,
.mat-form-field:not(.mat-form-field-disabled) .svg-icon {
	color: rgba(0, 0, 0, 0.54);
}
.mat-form-field:not(.mat-form-field-disabled) .placeholder {
	color: rgba(0, 0, 0, 0.54);
}
.mat-form-field:not(.mat-form-field-disabled).accent .icon-btn,
.mat-form-field:not(.mat-form-field-disabled).accent .mat-icon,
.mat-form-field:not(.mat-form-field-disabled).accent .read-only .mat-icon,
.mat-form-field:not(.mat-form-field-disabled).accent .svg-icon {
	color: #1464a5;
}
.mat-form-field .mat-disabled {
	color: rgba(0, 0, 0, 0.26);
}
.mat-form-field.mat-form-field-appearance-ontimize.read-only .mat-select-disabled .mat-select-value {
	color: inherit;
}
.mat-form-field.mat-form-field-appearance-ontimize.read-only .mat-form-field-underline {
	background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field.mat-form-field-invalid .mat-input-suffix .mat-icon {
	color: #f44336;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-start {
	border-radius: 2px 0 0 2px;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
	border-radius: 0 2px 2px 0;
}

input:not([type]):disabled,
input[type='color']:disabled,
input[type='date']:disabled,
input[type='datetime']:disabled,
input[type='datetime-local']:disabled,
input[type='email']:disabled,
input[type='month']:disabled,
input[type='number']:disabled,
input[type='password']:disabled,
input[type='search']:disabled,
input[type='tel']:disabled,
input[type='text']:disabled,
input[type='time']:disabled,
input[type='url']:disabled,
input[type='week']:disabled,
textarea:disabled {
	color: rgba(0, 0, 0, 0.38);
}

.o-tooltip.o-mat-error {
	background: white;
	border: 1px solid #f44336;
	color: #f44336;
}

/* CHECKBOX */
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-frame {
	border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-frame {
	border-color: rgba(0, 0, 0, 0.54);
}

.alert-icon.info {
	color: #03a9f4;
}

.alert-icon.warn {
	color: #ffc107;
}

.alert-icon.error {
	color: #f44336;
}

.mat-dialog {
	background-color: white;
}
.mat-dialog.mat-content-overflow .mat-actions,
.mat-dialog.mat-content-overflow mat-dialog-actions {
	border-top-color: rgba(0, 0, 0, 0.12);
}

.o-table .o-table-column-resizer span {
	background: rgba(0, 0, 0, 0.24);
}

.o-table .spinner-container {
	background-color: #eceff1;
}

.o-table .o-table-menu-button {
	color: rgba(0, 0, 0, 0.54);
}

.o-table .mat-table .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
	border-color: rgba(0, 0, 0, 0.18);
}

.o-table .mat-table .mat-header-row .mat-header-cell {
	color: rgba(0, 0, 0, 0.87);
}
.o-table .mat-table .mat-header-row .mat-header-cell .column-filter-icon {
	color: rgba(0, 0, 0, 0.12);
}
.o-table .mat-table .mat-header-row .mat-header-cell .column-filter-icon-active {
	color: #1464a5;
}
.o-table .mat-table .mat-header-row .mat-header-cell .o-table-header-indicator-numbered {
	color: rgba(0, 0, 0, 0.54);
}

.o-table .mat-table .mat-row.o-table-group-row-level-1 {
	font-weight: 900;
	background-color: #b2b2b2;
}

.o-table .mat-table .mat-row.o-table-group-row-level-2 {
	font-weight: 800;
	background-color: #cccccc;
}

.o-table .mat-table .mat-row.o-table-group-row-level-3 {
	font-weight: 700;
	background-color: #e8e8e8;
}

.o-table .mat-table .mat-row.o-table-group-row-level-4 {
	font-weight: 600;
	background-color: #f9f9f9;
}

.o-table .mat-table .mat-row:hover:not(.o-table-group-row) {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.18);
}

.o-table .mat-table .mat-row.selected {
	background: #e3ecf4;
}

.o-table .mat-table .mat-row .mat-cell {
	color: rgba(0, 0, 0, 0.54);
}

.o-table .mat-table .mat-footer-row.o-table-aggregate {
	font-weight: bold;
	background-color: #e0e0e0;
}

.o-table .mat-table .mat-footer-row .mat-cell.o-column-currency,
.o-table .mat-table .mat-footer-row .mat-cell.o-column-integer,
.o-table .mat-table .mat-footer-row .mat-cell.o-column-real,
.o-table .mat-table .mat-footer-row .mat-footer-cell.o-column-currency,
.o-table .mat-table .mat-footer-row .mat-footer-cell.o-column-integer,
.o-table .mat-table .mat-footer-row .mat-footer-cell.o-column-real,
.o-table .mat-table .mat-row .mat-cell.o-column-currency,
.o-table .mat-table .mat-row .mat-cell.o-column-integer,
.o-table .mat-table .mat-row .mat-cell.o-column-real,
.o-table .mat-table .mat-row .mat-footer-cell.o-column-currency,
.o-table .mat-table .mat-row .mat-footer-cell.o-column-integer,
.o-table .mat-table .mat-row .mat-footer-cell.o-column-real {
	text-align: right;
}

.o-table .mat-table .mat-footer-row .mat-cell.o-column-action,
.o-table .mat-table .mat-footer-row .mat-cell.o-column-boolean,
.o-table .mat-table .mat-footer-row .mat-cell.o-column-date,
.o-table .mat-table .mat-footer-row .mat-footer-cell.o-column-action,
.o-table .mat-table .mat-footer-row .mat-footer-cell.o-column-boolean,
.o-table .mat-table .mat-footer-row .mat-footer-cell.o-column-date,
.o-table .mat-table .mat-row .mat-cell.o-column-action,
.o-table .mat-table .mat-row .mat-cell.o-column-boolean,
.o-table .mat-table .mat-row .mat-cell.o-column-date,
.o-table .mat-table .mat-row .mat-footer-cell.o-column-action,
.o-table .mat-table .mat-row .mat-footer-cell.o-column-boolean,
.o-table .mat-table .mat-row .mat-footer-cell.o-column-date {
	text-align: center;
}

.o-table .mat-table.editable-row-table .mat-row,
.o-table .mat-table.totals-table .mat-row {
	background-color: rgba(224, 224, 224, 0.9);
}

.o-table .mat-table.totals-table .mat-header-row {
	display: none !important;
}

.o-table .mat-table.totals-table .mat-row {
	font-weight: bold;
}

.o-table .o-table-body.horizontal-scroll::-webkit-scrollbar-thumb {
	background-color: rgba(138, 178, 210, 0.5);
}

.o-table .o-table-body.horizontal-scroll::-webkit-scrollbar-thumb:hover {
	background-color: #8ab2d2;
}

.o-table .o-table-body.horizontal-scroll::-webkit-scrollbar-thumb {
	border: 6px solid;
	border-color: #eceff1;
}

.o-table .o-table-body.horizontal-scroll::-webkit-scrollbar-thumb:hover {
	border: 4px solid;
	border-color: #eceff1;
}

.o-table-visible-columns-dialog .mat-list-item.dnd-sortable-drag {
	background: #e3ecf4;
}

.o-table-quickfilter .quickFilter .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix div {
	border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.o-table .o-table-container .mat-table.small tr.mat-header-row:not(.o-table-insertable) {
	height: 28px;
}
.o-table .o-table-container .mat-table.small tr.mat-header-row:not(.o-table-insertable) .mat-header-cell {
	font-size: 12px;
}

.o-table .o-table-container .mat-table.small tr.mat-row {
	height: 28px;
}
.o-table .o-table-container .mat-table.small tr.mat-row .mat-cell {
	font-size: 11px;
}
.o-table .o-table-container .mat-table.small tr.mat-row .mat-cell .mat-form-field {
	font-size: 11px;
}

.o-table .o-table-container .mat-table.small tr.o-table-insertable.mat-header-row {
	height: 28px;
}
.o-table .o-table-container .mat-table.small tr.o-table-insertable.mat-header-row .mat-table-sticky {
	font-size: 11px;
}
.o-table .o-table-container .mat-table.small tr.o-table-insertable.mat-header-row .mat-table-sticky .mat-form-field {
	font-size: 11px;
}

.o-table .o-table-container .mat-table.small .o-table-no-results span {
	font-size: 11px;
	line-height: 28px;
}

.o-table-context-menu.small .mat-menu-item,
.o-table-menu.small .mat-menu-item {
	line-height: 28px;
	height: 28px;
}

.o-table-visible-columns-dialog .mat-list.small .mat-list-item {
	height: 28px;
}

.o-table .o-table-container .mat-table.medium tr.mat-header-row:not(.o-table-insertable) {
	height: 32px;
}
.o-table .o-table-container .mat-table.medium tr.mat-header-row:not(.o-table-insertable) .mat-header-cell {
	font-size: 13px;
}

.o-table .o-table-container .mat-table.medium tr.mat-row {
	height: 32px;
}
.o-table .o-table-container .mat-table.medium tr.mat-row .mat-cell {
	font-size: 12px;
}
.o-table .o-table-container .mat-table.medium tr.mat-row .mat-cell .mat-form-field {
	font-size: 12px;
}

.o-table .o-table-container .mat-table.medium tr.o-table-insertable.mat-header-row {
	height: 32px;
}
.o-table .o-table-container .mat-table.medium tr.o-table-insertable.mat-header-row .mat-table-sticky {
	font-size: 12px;
}
.o-table .o-table-container .mat-table.medium tr.o-table-insertable.mat-header-row .mat-table-sticky .mat-form-field {
	font-size: 12px;
}

.o-table .o-table-container .mat-table.medium .o-table-no-results span {
	font-size: 12px;
	line-height: 32px;
}

.o-table-context-menu.medium .mat-menu-item,
.o-table-menu.medium .mat-menu-item {
	line-height: 32px;
	height: 32px;
}

.o-table-visible-columns-dialog .mat-list.medium .mat-list-item {
	height: 32px;
}

.o-table .o-table-container .mat-table.large tr.mat-header-row:not(.o-table-insertable) {
	height: 40px;
}
.o-table .o-table-container .mat-table.large tr.mat-header-row:not(.o-table-insertable) .mat-header-cell {
	font-size: 15px;
}

.o-table .o-table-container .mat-table.large tr.mat-row {
	height: 40px;
}
.o-table .o-table-container .mat-table.large tr.mat-row .mat-cell {
	font-size: 14px;
}
.o-table .o-table-container .mat-table.large tr.mat-row .mat-cell .mat-form-field {
	font-size: 14px;
}

.o-table .o-table-container .mat-table.large tr.o-table-insertable.mat-header-row {
	height: 40px;
}
.o-table .o-table-container .mat-table.large tr.o-table-insertable.mat-header-row .mat-table-sticky {
	font-size: 14px;
}
.o-table .o-table-container .mat-table.large tr.o-table-insertable.mat-header-row .mat-table-sticky .mat-form-field {
	font-size: 14px;
}

.o-table .o-table-container .mat-table.large .o-table-no-results span {
	font-size: 14px;
	line-height: 40px;
}

.o-table-context-menu.large .mat-menu-item,
.o-table-menu.large .mat-menu-item {
	line-height: 40px;
	height: 40px;
}

.o-table-visible-columns-dialog .mat-list.large .mat-list-item {
	height: 40px;
}

.o-list-container .primary-text {
	color: rgba(0, 0, 0, 0.87);
}

.o-list-container .secondary-text {
	color: rgba(0, 0, 0, 0.54);
}

.o-list-container .o-list-row-action .mat-checkbox-inner-container,
.o-list-container .o-list-item-icon {
	color: rgba(0, 0, 0, 0.54);
}

.o-list-picker-dialog mat-toolbar .o-list-picker-search {
	font-size: 16px;
}
.o-list-picker-dialog mat-toolbar .o-list-picker-search.o-list-picker-search .mat-input-placeholder,
.o-list-picker-dialog mat-toolbar .o-list-picker-search .mat-icon {
	color: white;
}
.o-list-picker-dialog mat-toolbar .o-list-picker-search.o-list-picker-search .mat-input-underline {
	border-color: rgba(255, 255, 255, 0.78);
}
.o-list-picker-dialog mat-toolbar .o-list-picker-search.o-list-picker-search .mat-input-underline .mat-input-ripple {
	background-color: white;
}

.o-app-header nav.o-app-header-primary {
	background-color: #1464a5;
}

.o-app-header nav.o-app-header-accent {
	background-color: #1464a5;
}

.o-app-header nav .menu-button {
	background: transparent;
}

.o-app-header .o-user-info {
	color: rgba(0, 0, 0, 0.87);
}

.o-app-sidenav .o-app-sidenav-menu-group:not([disabled='true']) a.o-app-sidenav-viewer-sidenav-item-selected,
.o-app-sidenav .o-app-sidenav-menu-group:not([disabled='true']) a:hover,
.o-app-sidenav .o-app-sidenav-menu-item:not([disabled='true']) a.o-app-sidenav-viewer-sidenav-item-selected,
.o-app-sidenav .o-app-sidenav-menu-item:not([disabled='true']) a:hover {
	color: #063679;
	background-color: #f3f7fa;
}

.o-app-sidenav .o-app-sidenav-sidenav {
	background: #1464a5;
	/* Side Navigation Level Styles */
}
.o-app-sidenav .o-app-sidenav-sidenav .sidenav-toggle {
	color: white;
}
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-item {
	color: white;
}
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-item.o-user-info {
	color: inherit;
}
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-item.o-user-info:focus,
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-item.o-user-info:hover {
	color: inherit;
}
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-item.o-user-info .o-user-info-menu-item .o-user-info-item {
	background: rgba(255, 255, 255, 0.74);
}
.o-app-sidenav
	.o-app-sidenav-sidenav
	.o-app-sidenav-menu-group-level-1
	.o-app-sidenav-menugroup.opened
	+ .o-app-sidenav-menugroup-items-container
	ul {
	background: #0b488a;
}
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-menu-group-level-2 .o-app-sidenav-menugroup.opened + .o-app-sidenav-menugroup-items-container ul,
.o-app-sidenav .o-app-sidenav-sidenav .o-app-sidenav-menu-group-level-3 .o-app-sidenav-menugroup.opened + .o-app-sidenav-menugroup-items-container ul,
.o-app-sidenav
	.o-app-sidenav-sidenav
	.o-app-sidenav-menu-group-level-4
	.o-app-sidenav-menugroup.opened
	+ .o-app-sidenav-menugroup-items-container
	ul {
	background: #063679;
}
.o-app-sidenav .o-app-sidenav-sidenav li a {
	color: inherit;
}

.o-card-menu-item {
	background: white;
	border: 1px solid rgba(20, 100, 165, 0.17);
}
.o-card-menu-item .o-card-menu-item-main {
	background-color: rgba(236, 239, 241, 0.29);
}
.o-card-menu-item .o-card-menu-item-title {
	color: #1464a5;
	font-size: 18px;
}
.o-card-menu-item .mat-button {
	color: #1464a5;
	background: #b9d1e4;
}
.o-card-menu-item .o-card-menu-item-icon,
.o-card-menu-item .o-card-menu-item-info {
	color: rgba(20, 100, 165, 0.54);
}

ngx-material-timepicker-container ngx-material-timepicker-content .timepicker__header {
	background-color: #1464a5 !important;
}

ngx-material-timepicker-container ngx-material-timepicker-content .clock-face .clock-face__container .clock-face__number > span.active {
	background-color: #1464a5;
	color: #fff;
}

ngx-material-timepicker-container ngx-material-timepicker-content .clock-face .clock-face__clock-hand {
	background-color: #1464a5;
}

ngx-material-timepicker-container ngx-material-timepicker-content .clock-face .clock-face__clock-hand:after {
	background-color: #1464a5;
}

ngx-material-timepicker-container ngx-material-timepicker-content .clock-face .clock-face__clock-hand_minute:before {
	border: 4px solid #1464a5;
}

ngx-material-timepicker-container ngx-material-timepicker-content .timepicker-button {
	color: #1464a5;
}

ngx-material-timepicker-container ngx-material-timepicker-content .timepicker-dial__control {
	outline: none;
}

.o-form-layout-split-pane .spinner-container,
.o-form-layout-split-pane .o-split-pane-secondary-content {
	background-color: #eceff1;
}

.o-form-layout-tabgroup .spinner-container {
	background-color: #eceff1;
}

.o-form-layout-tabgroup .mat-tab-group .mat-tab-label .mat-icon:hover {
	background-color: rgba(0, 0, 0, 0.26);
	border-radius: 50%;
}

.o-form-layout-tabgroup .mat-tab-group .mat-tab-label.mat-tab-label-active .mat-icon:hover {
	background-color: rgba(20, 100, 165, 0.4);
}

/*background-color: mat-color($mat-primary);*/
.o-bar-menu {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.o-bar-menu nav .fake-ul .fake-ul {
	background-color: #fff;
}
.o-bar-menu nav .fake-ul .fake-ul .mat-icon,
.o-bar-menu nav .fake-ul .fake-ul .o-bar-menu-group-title,
.o-bar-menu nav .fake-ul .fake-ul .o-bar-menu-item-title,
.o-bar-menu nav .fake-ul .fake-ul label.toggle {
	color: rgba(0, 0, 0, 0.7);
}
.o-bar-menu nav .fake-ul .fake-ul .fake-li {
	background-color: #fff;
}
.o-bar-menu nav a {
	color: #fff;
}
@media all and (min-width: 768px) {
	.o-bar-menu nav .fake-ul .fake-li .fake-ul .fake-li-hover {
		background: rgba(0, 0, 0, 0.16);
	}
	.o-bar-menu nav .fake-ul .fake-li-hover {
		background-color: rgba(255, 255, 255, 0.16);
	}
}
@media all and (max-width: 767px) {
	.o-bar-menu nav a:hover {
		background-color: rgba(255, 255, 255, 0.15);
	}
	.o-bar-menu nav .fake-ul {
		border-top: 1px solid rgba(255, 255, 255, 0.5);
	}
	.o-bar-menu .toggle:hover {
		background-color: rgba(255, 255, 255, 0.15);
	}
}

.o-grid .mat-toolbar {
	padding: 0 4px;
	background: transparent;
}

.o-grid .o-grid-container .o-loading-blocker .o-spinner-container {
	background-color: #eceff1;
}

[layout-padding-vertical] {
	padding: 4px 0;
}

[layout-margin-vertical] {
	margin: 4px 0;
}

[layout-padding-horizontal] {
	padding: 0 14px;
}

[layout-margin-horizontal] {
	margin: 0 14px;
}

[layout-padding-left] {
	padding-left: 14px;
}

[layout-margin-left] {
	margin-left: 14px;
}

[layout-padding-right] {
	padding-right: 14px;
}

[layout-margin-right] {
	margin-right: 14px;
}

[layout-padding-top] {
	padding-top: 4px;
}

[layout-margin-top] {
	margin-top: 4px;
}

[layout-padding-bottom] {
	padding-bottom: 4px;
}

[layout-margin-bottom] {
	margin-bottom: 4px;
}

/*
  *  variables
  */
/*
* styles
*/
.md-drppicker {
	background-color: #eceff1;
	/* ranges */
}
.md-drppicker:after,
.md-drppicker:before {
	border-bottom-color: rgba(0, 0, 0, 0.12);
}
.md-drppicker .calendar-table {
	border-color: rgba(0, 0, 0, 0.12);
}
.md-drppicker th {
	font-weight: normal;
}
.md-drppicker td,
.md-drppicker th {
	text-align: center;
	border-color: rgba(0, 0, 0, 0.12);
}
.md-drppicker td.available:hover,
.md-drppicker th.available:hover {
	background: rgba(0, 0, 0, 0.12);
}
.md-drppicker td.week,
.md-drppicker th.week {
	font-size: 80%;
	color: #ccc;
}
.md-drppicker td.off,
.md-drppicker td.off.end-date,
.md-drppicker td.off.in-range,
.md-drppicker td.off.start-date {
	background-color: #eceff1;
	border-color: transparent;
	color: #999999;
}
.md-drppicker td.in-range {
	background-color: #75a7ff;
	border-color: transparent;
}
.md-drppicker td.active {
	background-color: #1464a5;
	color: white;
}
.md-drppicker td.active,
.md-drppicker td.active:hover {
	background-color: #1464a5;
}
.md-drppicker option.disabled,
.md-drppicker td.disabled {
	color: #999;
}
.md-drppicker .dropdowns select {
	background-color: rgba(255, 255, 255, 0.9);
}
.md-drppicker .dropdowns select.ampmselect,
.md-drppicker .dropdowns select.hourselect,
.md-drppicker .dropdowns select.minuteselect,
.md-drppicker .dropdowns select.secondselect {
	width: 50px;
	margin: 0 auto;
	background: #eee;
	border: 1px solid #eee;
	padding: 2px;
	outline: 0;
	font-size: 12px;
}
.md-drppicker .calendar-time .select .select-item {
	background-color: transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	/* Remove focus */
}
.md-drppicker .calendar-time .select .select-item:after {
	border-top: 6px solid rgba(0, 0, 0, 0.12);
}
.md-drppicker .calendar-time .select .select-item .select-label {
	color: rgba(0, 0, 0, 0.26);
}
.md-drppicker .calendar-time select.disabled {
	color: #999999;
}
.md-drppicker .show-ranges .drp-calendar.left {
	border-left: 1px solid #ddd;
}
.md-drppicker .ranges ul li button.active {
	background: rgba(0, 0, 0, 0.12);
}
.md-drppicker .ranges ul li:hover {
	background: rgba(0, 0, 0, 0.12);
}

.o-dual-list-selector .o-dual-list-selector-list .mat-selection-list.mat-list-base {
	border: 1px solid rgba(0, 0, 0, 0.12);
}
.o-dual-list-selector .o-dual-list-selector-list .mat-selection-list.mat-list-base .mat-list-item:not(.mat-list-option) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.o-dual-list-selector .o-dual-list-selector-list .mat-selection-list.mat-list-base .mat-list-item.mat-list-option {
	background: rgba(0, 0, 0, 0.04);
}
.o-dual-list-selector .o-dual-list-selector-list .mat-selection-list.mat-list-base .mat-list-item.mat-list-option[aria-selected='true'] {
	background-color: #f3f7fa;
	color: rgba(0, 0, 0, 0.87);
}
.o-dual-list-selector .o-dual-list-selector-list .mat-selection-list.mat-list-base.cdk-drop-list-dragging {
	border-style: dashed;
	border-width: 2px;
}

.o-dual-list-selector .o-dual-list-selector-list .mat-mini-fab:not([class*='mat-elevation-z']) {
	box-shadow: none;
}
.o-dual-list-selector .o-dual-list-selector-list .mat-mini-fab:not([class*='mat-elevation-z']).mat-accent:not([disabled]) {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.87);
}

.o-image .o-image-content .o-image-display-container.o-image-drag-and-drop:not(.o-image-drag-and-drop-required) {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(0, 0, 0, 0.54)' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.o-image .o-image-content .o-image-display-container.o-image-drag-and-drop.o-file-dragging {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(0, 0, 0, 0.54)' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.o-image .o-image-content .o-image-display-container.o-image-drag-and-drop-required:not(.o-file-dragging) {
	border-color: #f44336;
}

.o-image .o-image-content .o-image-display-container .o-image-button-remove {
	background-color: #eceff1;
}
.o-image .o-image-content .o-image-display-container .o-image-button-remove:hover {
	background-color: rgba(0, 0, 0, 0.26);
}

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.login-outer-container {
	background-color: #eceff1;
}
.login-outer-container .wrapp-login {
	background-color: white;
}
.login-outer-container .wrapp-login .login-inner-container {
	background-color: white;
}
.login-outer-container .wrapp-login .login-inner-container .logo-container {
	background-color: white;
}
.login-outer-container .wrapp-login .login-inner-container .session-expired {
	color: #f44336;
}
.login-outer-container .wrapp-login .login-inner-container .login_info,
.login-outer-container .wrapp-login .login-inner-container .title_text {
	color: rgba(0, 0, 0, 0.87);
}
.login-outer-container .wrapp-login .login-inner-container .link_text {
	color: #1464a5;
}

.footer-container {
	background-color: #1464a5;
}
.footer-container div span {
	color: white;
}
