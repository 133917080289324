@use 'ontimize-web-ngx/theming/themes/ontimize.scss' as theme;
@use 'ontimize-web-ngx/theming/ontimize-style.scss';
@use '../../app/login/login.theme.scss' as login;

// Include ontimize styles
@include ontimize-style.ontimize-theme-styles(theme.$theme);

// Propagate theme to screen styles definition.
@include login.login-theme(theme.$theme);


//Include dark styles option
.o-dark {
  @include ontimize-style.ontimize-theme-all-component-color(theme.$dark-theme);
}
