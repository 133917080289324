@use '@angular/material' as mat;
@use 'sass:map';

@mixin login-theme($theme) {
	$primary: map.get($theme, color, primary);
	$accent: map.get($theme, color, accent);
	$warn: map.get($theme, color, warn);
	$background: map.get($theme, color, background);
	$foreground: map.get($theme, color, foreground);

	.login-outer-container {
		background-color: mat.get-color-from-palette($background, background);

		.wrapp-login {
			background-color: mat.get-color-from-palette($background, dialog);

			.login-inner-container {
				background-color: mat.get-color-from-palette($background, dialog);

				.logo-container {
					background-color: mat.get-color-from-palette($background, dialog);
				}

				.session-expired {
					color: #f44336;
				}

				.title_text,
				.login_info {
					color: mat.get-color-from-palette($foreground, text);
				}

				.link_text {
					color: mat.get-color-from-palette($primary);
				}
			}
		}
	}

	.footer-container {
		background-color: mat.get-color-from-palette($primary);

		div {
			span {
				color: white;
			}
		}
	}
}
