@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'assets/themes/ontimize.css';

$intertech-web: (
	50: #e3ecf4,
	100: #b9d1e4,
	200: #8ab2d2,
	300: #5b93c0,
	400: #377bb3,
	500: #1464a5,
	600: #125c9d,
	700: #0e5293,
	800: #0b488a,
	900: #063679,
	A100: #a8c7ff,
	A200: #75a7ff,
	A400: #4286ff,
	A700: #2876ff,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #ffffff,
		A700: #ffffff,
	),
);

:root {
	--intertech-web-background: #eceff1;
	--intertech-web-surface: #ffffff;
	--intertech-web-surface-element: #fafaf9;
	--intertech-web-sidenav: #1464a5;
	--intertech-web-background-hover: #e3ecf4;
	--intertech-web-background-danger: #ff000066;

	--intertech-web-text-primary: #121212;
	--intertech-web-text-secondary: #626262;

	--intertech-web-color-black: rgba(0, 0, 0, 0.12);
	--intertech-web-color-filter: #b9d1e4;
	--intertech-web-color-new: #0e5293;

	--intertech-web-loader-color-1: #8ab2d2;
	--intertech-web-loader-color-2: #377bb3;
	--intertech-web-loader-color-3: #0b488a;

  --intertech-web-sidenav-principal: #1464a5;
  --intertech-web-sidenav-secundary: #0b488a;
  --intertech-web-sidenav-tertiary: #063679;
}

html,
body {
	height: 100%;
	width: 100%;
	color: var(--intertech-web-text-primary);
	font-size: 13px;
}

@font-face {
	font-family: 'Poppins';
	src: url('../src/assets/fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Poppins';
	src: url('../src/assets/fonts/Poppins-Regular.woff') format('woff');
	font-weight: normal;
}
@font-face {
	font-family: 'Poppins';
	src: url('../src/assets/fonts/Poppins-Regular.woff2') format('woff2');
	font-weight: normal;
}

@font-face {
	font-family: 'NotoSans';
	src: url('../src/assets/fonts/NotoSans-Bold.ttf') format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'NotoSans';
	src: url('../src/assets/fonts/NotoSans-Bold.woff') format('woff');
	font-weight: 700;
}
@font-face {
	font-family: 'NotoSans';
	src: url('../src/assets/fonts/NotoSans-Bold.woff2') format('woff2');
	font-weight: 700;
}

* {
	font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
}

@include mat.core();

$intertech-web-primary: mat.define-palette($intertech-web);

$theme: (
	bg-color: var(--intertech-web-surface),
	color: var(--intertech-web-text-secondary),
	hover-bg-color: var(--intertech-web-background-hover),
	hover-color: var(--intertech-web-sidenav),
	disabled-bg-color: var(--intertech-web-surface),
	disabled-color: var(--intertech-web-text-secondary),
);

$primary: map-get($theme, gain-web-primary);

button {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	border-radius: 2px;
	font-size: 13px;
	height: 32px;
	padding-left: 16px;
	padding-right: 16px;
}
button:hover {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-background-hover);
	cursor: pointer;
}
button:disabled {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	cursor: not-allowed;
}

button.compact {
	height: 28px;
}

button.mat-primary {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	border-radius: 2px;
	font-size: 13px;
}
button.mat-primary:hover {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-background-hover);
	cursor: pointer;
}
button.mat-primary:disabled {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	opacity: 0.5;
	cursor: not-allowed;
}

button.next {
	color: var(--intertech-web-surface);
	background-color: var(--intertech-web-sidenav);
	font-size: 13px;
	border: 0;
	margin-left: 15px;
}
button.next:hover {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-background-hover);
}
button.next:disabled {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	opacity: 0.5;
	cursor: not-allowed;
}

button.withicon {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	border-radius: 2px;
	font-size: 13px;
	height: 32px;
	.mat-icon {
		vertical-align: middle;
		margin-right: 5px;
	}
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	display: block;
	padding: 24px;
	border-radius: 4px;
	box-sizing: border-box;
	overflow: auto;
	outline: 0;
	width: 100%;
	height: 100%;
	min-height: inherit;
	max-height: inherit;
}

.mdc-tab-indicator {
	display: none !important;
}

button.withicon:hover {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-background-hover);
	cursor: pointer;
}
button.withicon:disabled {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	opacity: 0.5;
	cursor: not-allowed;
}

button.withicon.mat-primary {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	border-radius: 2px;
	font-size: 13px;
	.mat-icon {
		width: 20px;
		height: 20px;
		vertical-align: middle;
		margin-bottom: 5px;
		margin-right: 5px;
	}
}
button.withicon.mat-primary:hover {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-background-hover);
	border: 0px;
	cursor: pointer;
}
button.withicon.mat-primary:disabled {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	opacity: 0.5;
	cursor: not-allowed;
}

button.filter {
	color: var(--intertech-web-text-primary);
	background-color: var(--intertech-web-color-filter);
	border: none;
}

.link {
	color: var(--intertech-web-sidenav);
	cursor: pointer;
	text-decoration: none;
}

button.new {
	color: var(--intertech-web-surface);
	background-color: var(--intertech-web-color-new);
	border: 0px;
	font-size: 13px;
}
button.new:hover {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-background-hover);
	cursor: pointer;
}
button.new:disabled {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
	border: 1px solid var(--intertech-web-color-black);
	border-radius: 2px;
	opacity: 0.5;
	cursor: not-allowed;
}

.mat-icon.type1 {
	color: var(--intertech-web-text-secondary);
	background-color: var(--intertech-web-surface);
}
.mat-icon.type2 {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-surface);
}
.mat-icon.type3 {
	color: var(--intertech-web-sidenav);
	background-color: var(--intertech-web-color-filter);
}
.mat-icon.type4 {
	color: var(--intertech-web-surface);
	background-color: var(--intertech-web-sidenav);
}

.mat-mdc-checkbox {
	color: var(--intertech-web-surface);
	:checked {
		background-color: var(--intertech-web-sidenav);
	}
}

.mat-mdc-table.mat-mdc-header-row {
	font-family: 'NotoSans';
}

/* You can add global styles to this file, and also import other style files */
.o-form-layout-manager {
	.o-form {
		form {
			height: 100%;
		}
	}
}

/* Form toolbar background color */
.o-form-toolbar .o-form-toolbar-header.mat-toolbar {
	background-color: transparent;
}

.o-column > div.o-container .o-container-scroll.o-container-gap,
.o-row > div.o-container .o-container-scroll.o-container-gap {
	padding: 15px;
}

.avatar.o-image {
	width: 200px;
	height: 200px;

	.o-image-content.o-image-auto-fit {
		.o-image-display-container {
			img {
				border-radius: 50%;
				aspect-ratio: auto 200/200;
				width: 200px;
				height: 200px;
			}
		}

		.o-image-form-field {
			position: absolute;
			bottom: -42px;
		}
	}
}

/*snack bars*/
.cdk-overlay-pane {
	.mat-mdc-snack-bar-container.done-snack {
		max-width: unset;
		background: rgba(8, 116, 8, 0.829);
	}
}

.cdk-overlay-pane {
	.mat-mdc-snack-bar-container.error-snack {
		max-width: unset;
		background: rgb(202, 25, 25);
	}
}

.title {
	text-transform: uppercase;
	color: #007bc4;
}

.mat-label {
  color: #007bc4;
}

/*mat-errors*/
.mat-mdc-form-field-error {
	display: block !important;
}

.mat-mdc-form-field-subscript-wrapper {
	position: relative !important;
}

.mat-mdc-tab:not(.mdc-tab--active) {
	background-color: rgba(0, 0, 0, 0.05);
}

/*mat-form-field text crop*/
.mat-mdc-floating-label {
	overflow: visible !important;
}

.mat-mdc-tooltip {
	background-color: white !important;
	color: black !important;
	width: auto;
	font-size: 13px;
	box-shadow: 2px 2px var(--intertech-web-text-secondary);
}

.mat-tree {
	margin-left: 5px;
}

.mat-tree .mat-tree-node {
	font-size: 13px;
	min-height: 25px;
}

.mat-tree .mat-tree-node .mat-checkbox-layout .mdc-label {
	max-width: 350px;
	white-space: initial;
}

.mat-grid-tile .mat-figure {
	display: block !important;
	min-height: 100%;
	margin: 0;
	padding: 0;
}

.mat-grid-tile .filter {
	margin-right: 1px solid var(--intertech-web-text-secondary) !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group .mat-mdc-tab-body-wrapper {
	padding: 0 !important;
	background-color: var(--intertech-web-surface);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
	flex-grow: 0 !important;
}

.mat-ink-bar {
	display: none;
}

.mdc-tab--active {
	background-color: var(--intertech-web-surface);
}

.mat-mdc-tab-header {
	border-bottom: none !important;
	background-color: var(--intertech-web-background);
}

.mat-mdc-tab:not(.mdc-tab--active) {
	background-color: #ebebeb;
	border-bottom: none;
}

.mat-icon.close {
	color: black;
	padding-left: 12px;
	font-size: 15px;
	padding-top: 3px;
}

.o-app-sidenav
	.o-app-sidenav-sidenav
	.o-app-sidenav-menu-group-level-1
	.o-app-sidenav-menugroup.opened
	+ .o-app-sidenav-menugroup-items-container
	ul {
	background-color: #1464a5 !important;
}

/* Mat-icon sufix centered inside form field */
.mat-mdc-form-field-text-prefix,
.mat-mdc-form-field-text-suffix {
	position: static !important;
	cursor: pointer;
}
